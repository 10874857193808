import {
  isAuctionRelease,
  isAvailable,
  isDrawRelease,
  isInNext24Hours,
  isInNextSixDays,
  isPrivateRelease,
  isSoldOut,
  isTimedRelease,
  isUpcoming,
} from '../../../utils/product';

type Status = {
  key: string;
  active: boolean;
};

export const getTileStatus = (
  product: Product,
  isPreOrder?: boolean,
): Status | null => {
  if (product.date === undefined) return null;

  if (isSoldOut(product)) {
    return { key: 'tileStatus.soldOut', active: false };
  }

  if (isUpcoming(product) && !isDrawRelease(product)) {
    return { key: 'tileStatus.upcoming', active: false };
  }

  if (isUpcoming(product) && isDrawRelease(product)) {
    return isPreOrder
      ? { key: 'tileStatus.preOrder.upcoming', active: true }
      : { key: 'tileStatus.draw.upcoming', active: true };
  }

  if (isPrivateRelease(product)) {
    if (product.fewLeft) {
      return { key: 'tileStatus.private.fewLeft', active: true };
    }
    return { key: 'tileStatus.private.default', active: true };
  }

  if (
    (isTimedRelease(product) || isAuctionRelease(product)) &&
    product.closingDate
  ) {
    if (isAvailable(product)) {
      let statusKey = 'tileStatus.timed.active.long';

      if (isInNext24Hours(new Date(product.closingDate))) {
        statusKey = 'tileStatus.timed.active.short';
      }
      return { key: statusKey, active: true };
    }
  }

  if (
    isAvailable(product) &&
    !(isPrivateRelease(product) || isTimedRelease(product))
  ) {
    if (product.fewLeft) {
      return { key: 'tileStatus.available.fewLeft', active: true };
    }
    return { key: 'tileStatus.available.default', active: true };
  }

  return null;
};

export const getBannerStatus = (
  product: Product,
  isPreOrder?: boolean,
): Status | null => {
  if (product.date === undefined) return null;

  if (isSoldOut(product)) {
    return { key: 'bannerStatus.soldOut', active: false };
  }

  if (isTimedRelease(product) || isAuctionRelease(product)) {
    if (isUpcoming(product)) {
      let statusKey = 'bannerStatus.timed.upcoming.date';

      if (isInNextSixDays(new Date(product.date))) {
        statusKey = 'bannerStatus.timed.upcoming.countdown.long';
      }

      if (isInNext24Hours(new Date(product.date))) {
        statusKey = 'bannerStatus.timed.upcoming.countdown.short';
      }

      return { key: statusKey, active: false };
    }

    if (isAvailable(product)) {
      if (isAuctionRelease(product)) {
        const statusKey = 'bannerStatus.auction.active';
        return { key: statusKey, active: true };
      }

      if (product.closingDate) {
        let statusKey = 'bannerStatus.timed.active.long';

        if (isInNext24Hours(new Date(product.closingDate))) {
          statusKey = 'bannerStatus.timed.active.short';
        }
        return { key: statusKey, active: true };
      }

      return { key: 'bannerStatus.available.default', active: true };
    }
  }

  if (isDrawRelease(product)) {
    if (isUpcoming(product)) {
      if (isPreOrder) {
        return { key: 'bannerStatus.preOrder.upcoming.date', active: true };
      }

      let statusKey = 'bannerStatus.draw.upcoming.date';

      if (isInNextSixDays(new Date(product.date))) {
        statusKey = 'bannerStatus.draw.upcoming.countdown.long';
      }

      if (isInNext24Hours(new Date(product.date))) {
        statusKey = 'bannerStatus.draw.upcoming.countdown.short';
      }

      return { key: statusKey, active: true };
    }
  }

  if (isPrivateRelease(product)) {
    if (product.fewLeft) {
      return { key: 'bannerStatus.private.fewLeft', active: true };
    }
    return { key: 'bannerStatus.private.default', active: true };
  }

  if (isUpcoming(product)) {
    let statusKey = 'bannerStatus.upcoming.date';

    if (isInNextSixDays(new Date(product.date))) {
      statusKey = 'bannerStatus.upcoming.countdown.long';
    }

    if (isInNext24Hours(new Date(product.date))) {
      statusKey = 'bannerStatus.upcoming.countdown.short';
    }

    return { key: statusKey, active: false };
  }

  if (isAvailable(product)) {
    let statusKey = 'bannerStatus.available.default';

    if (product.fewLeft) {
      statusKey = 'bannerStatus.available.fewLeft';
    }

    return { key: statusKey, active: true };
  }

  return null;
};

export const getUpcomingDescription = (product: Product): string => {
  if (isTimedRelease(product)) {
    if (isInNextSixDays(new Date(product.date))) {
      return 'upcomingDescription.timed.short';
    }
    return 'upcomingDescription.timed.long';
  }

  if (isDrawRelease(product)) {
    if (isInNextSixDays(new Date(product.date))) {
      return 'upcomingDescription.draw.short';
    }
    return 'upcomingDescription.draw.long';
  }

  if (isAuctionRelease(product)) {
    return 'upcomingDescription.auction';
  }

  if (isInNextSixDays(new Date(product.date))) {
    return 'upcomingDescription.default.short';
  }
  return 'upcomingDescription.default.long';
};
