import { useContext, useEffect } from 'react';

import { TimeContext } from '../context/TimeContext';

export const useTime = () => {
  const ctx = useContext(TimeContext);

  useEffect(() => {
    ctx.startListening();
    return () => ctx.stopListening();
  }, []);

  return ctx.now;
};
