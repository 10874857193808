export type Sorter<T> = (a: T, b: T) => number;

export function combineSorters<T>(sorters: Sorter<T>[]): Sorter<T> {
  return (a, b) => {
    let result = 0;
    for (let i = 0; i < sorters.length; i += 1) {
      const sorter = sorters[i];

      result = sorter(a, b);
      if (result !== 0) {
        break;
      }
    }
    return result;
  };
}
