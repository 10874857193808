import { createContext } from 'react';

type TimeContextType = {
  now: Date;
  startListening(): void;
  stopListening(): void;
};

export const TimeContext = createContext<TimeContextType>({
  now: new Date(),
  startListening: () => {},
  stopListening: () => {},
});
